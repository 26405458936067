import Cookie from "js-cookie";


const api_url = process.env.REACT_APP_API_URL;

export default function getData(endpoint, method) {
  return fetch(`${api_url}${endpoint}`,
    {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${Cookie.get('token')}`
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error during getData');
      }
      return response.json();
    }).catch((error) => {
      throw new Error(error);
    });
}
